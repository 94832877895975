@font-face {
  font-family: "OfficeCodeProWeb";
  src:
    url("./officeCodePro/OfficeCodePro-Bold.woff2") format("woff2"),
    url("./officeCodePro/OfficeCodePro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "OfficeCodeProWeb";
  src:
    url("./officeCodePro/OfficeCodePro-BoldItalic.woff2") format("woff2"),
    url("./officeCodePro/OfficeCodePro-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "OfficeCodeProWeb";
  src:
    url("./officeCodePro/OfficeCodePro-MediumItalic.woff2") format("woff2"),
    url("./officeCodePro/OfficeCodePro-MediumItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "OfficeCodeProWeb";
  src:
    url("./officeCodePro/OfficeCodePro-Medium.woff2") format("woff2"),
    url("./officeCodePro/OfficeCodePro-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "OfficeCodeProWeb";
  src:
    url("./officeCodePro/OfficeCodePro-RegularItalic.woff2") format("woff2"),
    url("./officeCodePro/OfficeCodePro-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "OfficeCodeProWeb";
  src:
    url("./officeCodePro/OfficeCodePro-Regular.woff2") format("woff2"),
    url("./officeCodePro/OfficeCodePro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OfficeCodeProWeb";
  src:
    url("./officeCodePro/OfficeCodePro-LightItalic.woff2") format("woff2"),
    url("./officeCodePro/OfficeCodePro-LightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "OfficeCodeProWeb";
  src:
    url("./officeCodePro/OfficeCodePro-Light.woff2") format("woff2"),
    url("./officeCodePro/OfficeCodePro-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
